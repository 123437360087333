*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-decoration: none
  font-family: 'Roboto', sans-serif !important
  font-weight: 500 !important
  font-size: 24px !important
  line-height: 32px !important
  color: #252525 !important
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box
  @at-root .widget-application--smallMobile &
    font-size: 16px !important

html
  --preloader-size: 24px
  @media (max-width: 1024px)
    --preloader-size: 18px
  @media (max-width: 320px)
    --preloader-size: 12px

body
  width: 100%
  height: 100%
  overflow: hidden

.widget-section
  width: 100%
  height: 100%
  .widget
    width: 100%
    height: 100%
    &-ru
      .widget-instructions
        &__hint p
          font-size: 14px !important
          color: #413e3e !important
          @media (max-width: 670px)
            font-size: 10px !important
            line-height: 125% !important
          @media screen and (min-width: 2200px)
            font-size: 1rem !important
            line-height: 1.4rem !important
        &__step p
          @media (max-width: 670px)
            font-size: 20px !important
          @media (max-width: 320px)
            font-size: 18px !important
          @media screen and (min-width: 2200px)
            font-size: 1rem !important
            line-height: 1.4rem !important

.widget-instructions__content
  background-color: #DFDFDF !important
  @media screen and (max-width: 300px)
    max-width: calc( 100% - 20px ) !important
  @media screen and (max-width: 300px)
    max-width: calc( 100% - 15px ) !important
  @media screen and (min-width: 2200px)
    width: 20rem !important
    padding: 1.8rem 1.5rem 1.5rem 1.5rem !important
    border-radius: 1rem !important
  button
    @media (max-width: 670px)
      height: 35px !important
      padding: 0 33px !important
    @media screen and (min-width: 2200px)
      height: 2.2rem !important
      padding: 0 1.65rem !important
      margin: 1.25rem auto 0 auto !important

.widget-watermark-button
  display: none

.widget-tab__panorama-controls
  display: none !important
  &-button
    &--ruler, &--scale
      background: rgba(235, 235, 235, 0.85) !important
      width: 52px !important
      height: 52px !important
      margin-right: 0 !important
      @at-root .widget-application--mobile &
        width: 36px !important
        height: 36px !important
      @media screen and (min-width: 2200px)
        height: 2.17rem !important
        width: 2.17rem !important
    &--scale
      img
        display: none !important
      span
        pointer-events: none !important
        font-size: 20px !important
        line-height: 28px !important
        font-weight: 400 !important
        @at-root .widget-application--mobile &
          font-size: 13px !important
          line-height: 20px !important
    &--ruler img
      width: 24px !important
      height: 24px !important
      @media (max-width: 1100px)
        width: 34px !important
        height: 34px !important
      @media screen and (min-width: 2200px)
        width: 1.2rem !important
        height: 1.2rem !important

.widget-tab__panorama-map
  left: unset !important
  right: 45px
  &::before
    left: unset !important
    right: 50% !important
    transform: translate(50%, -50%) !important
  @at-root .widget-application--tablet &
    right: 35px !important
    transform-origin: 100% 100% !important
  @at-root .widget-application--mobile &
    right: 22px !important
  @at-root .widget-application--smallMobile &
    right: 15px !important
  @media screen and (min-width: 2200px)
    bottom: 1.75rem !important
    right: 2.25rem !important
    transform: scale(1.3) translate(-20%, -20%) !important
  @media screen and (min-width: 2560px)
    transform: scale(1.6) translate(-25%, -25%) !important
  @media screen and (min-width: 2960px)
    transform: scale(2) translate(-25%, -30%) !important
  @media screen and (min-width: 5000px)
    transform: scale(2.5) translate(-30%, -35%) !important
  &--scaled
    @at-root .widget-application--tablet &
      transform: scale(1.4) translate(50%, 50%) !important
      left: unset !important
      right: 50% !important
      &::after
        left: unset !important
        right: 50% !important
        transform: translate(50%, -50%) !important
    @at-root .widget-application--mobile &
      transform: scale(1) translate(50%, 50%) !important

.widget-instructions__content
  button
    font-size: 24px !important
    text-transform: uppercase !important
    @media screen and (min-width: 2200px)
      font-size: 1.2rem !important

.widget-instructions__step
  @media screen and (min-width: 2200px)
    margin: 0 0.75rem !important
  p
    font-size: 24px !important
    line-height: 30px !important
    max-width: 155px !important
    @media screen and (min-width: 2200px)
      font-size: 1.2rem !important
      line-height: 1.25rem !important
      max-width: 7.75rem !important
  img
    @media screen and (min-width: 2200px)
      width: 2.6rem !important
      height: 4rem !important

.widget-instructions__hint
  p
    font-size: 16px !important
    line-height: 20px !important
    @media screen and (min-width: 2200px)
      font-size: 0.66rem !important
      line-height: 0.83rem !important

.widget-tab
  &-buttons
    left: 50% !important
    justify-content: flex-start !important
    column-gap: 8px !important
    right: unset !important
    background: rgba(235, 235, 235, 0.3) !important
    padding: 8px !important
    border-radius: 60px
    transform: translateX(-50%)
    @at-root .widget-application--mobile &
      left: 12px !important
      padding: 6px !important
      transform: none !important
    @media (max-width: 670px)
      bottom: 25px !important
    @media screen and (min-width: 2200px)
      bottom: 2.15rem !important
      padding: 0.4rem !important
      column-gap: 0.4rem !important
  &-button
    margin-left: 0 !important
    background-color: rgba(235, 235, 235, 0.85) !important
    font-size: 20px !important
    line-height: 28px !important
    font-weight: 400 !important
    width: 52px !important
    height: 52px !important
    @at-root .widget-application--mobile &
      font-size: 13px !important
      line-height: 20px !important
      width: 36px !important
      height: 36px !important
    @media screen and (min-width: 2200px)
      font-size: 1rem !important
      line-height: 1.4rem !important
      width: 2.17rem !important
      height: 2.17rem !important
    &--active
      background-color: #FFD180 !important
  &__isometry
    &-container
      img
        @media screen and (min-width: 2200px)
          width: 30rem !important
          height: 30rem !important
          max-width: 30rem !important
          max-height: 30rem !important
    &-buttons
      right: 45px !important
      bottom: 43px !important
      left: unset !important
      @at-root .widget-application--mobile &
        right: 15px !important
        bottom: 31px !important
      @media screen and (min-width: 2200px)
        right: 2.25rem !important
        bottom: 2.55rem !important
    &-button
      width: 52px !important
      height: 52px !important
      background-color: rgba(235, 235, 235, 0.85) !important
      left: unset !important
      bottom: 29px !important
      @media (max-width: 670px)
        bottom: 29px !important
        width: 36px !important
        height: 36px !important
      @media (max-width: 375px)
        width: 36px !important
        height: 36px !important
        bottom: 29px !important
      @media screen and (min-width: 2200px)
        width: 2.17rem !important
        height: 2.17rem !important
      img
        width: 36px !important
        height: 36px !important
        @media (max-width: 670px)
          width: 25px !important
          height: 25px !important
        @media screen and (min-width: 2200px)
          width: 1.7rem !important
          height: 1.7rem !important
    &-arrows
      position: relative !important
      left: unset !important
      bottom: unset !important
      justify-content: space-between !important
      background-color: rgba(235, 235, 235, 0.85) !important
      width: 107px !important
      height: 52px !important
      padding: 7px !important
      border-radius: 60px !important
      transform: none !important
      @at-root .widget-application--mobile &
        width: 74px !important
        height: 36px !important
      @media screen and (min-width: 2200px)
        width: 5.35rem !important
        height: 2.17rem !important
        border-radius: 1.5rem !important
        padding: 0.35rem !important
      &::before
        display: none !important
      span
        font-size: 20px !important
        line-height: 28px !important
        font-weight: 400 !important
        margin: 0 !important
        @at-root .widget-application--mobile &
          font-size: 13px !important
          line-height: 20px !important
        @media screen and (min-width: 2200px)
          font-size: 1rem !important
          line-height: 1.4rem !important
      img
        width: 20px !important
        height: 20px !important
        @at-root .widget-application--mobile &
          width: 15px !important
          height: 15px !important
        @media screen and (min-width: 2200px)
          width: 1rem !important
          height: 1rem !important
    &-arrow
      padding: 0 !important
  &__rotation
    .widget-tab__isometry-arrows
      display: none

.widget-application
  & > .floors-select
    display: none
// Preloader
.widget-preloader-frame
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  position: absolute
  left: 0
  top: 0
  z-index: 1000
  background-color: #fff
  transition: opacity 2s

  &.hidden, &.hidden .widget-preloader
    pointer-events: none
    opacity: 0

  .widget-preloader
    font-size: var(--preloader-size)
    width: 5em
    height: 5em
    position: relative

    div
      width: 1em
      height: 1em
      position: absolute
      top: 2em
      border-radius: 50%
      background-color: #FFD180
      animation-timing-function: cubic-bezier(0, 1, 1, 0)
      &:nth-child(1)
        left: 0em
        animation: widget-preloader-1 0.6s infinite
      &:nth-child(2)
        left: 0em
        animation: widget-preloader-2 0.6s infinite
      &:nth-child(3)
        left: 2em
        animation: widget-preloader-2 0.6s infinite
      &:nth-child(4)
        left: 4em
        animation: widget-preloader-3 0.6s infinite

@keyframes widget-preloader-1
  0%
    transform: scale(0)
  100%
    transform: scale(1)

@keyframes widget-preloader-2
  0%
    transform: translateX(0)
  100%
    transform: translateX(2em)

@keyframes widget-preloader-3
  0%
    transform: scale(1)
  100%
    transform: scale(0)

.widget-loader div
  &:nth-child(1), &:nth-child(2), &:nth-child(3)
    background-color: #FFD180 !important

.floors-select
  position: relative !important
  top: unset !important
  left: unset !important
  height: 52px !important
  background: rgba(235, 235, 235, 0.85) !important
  border-bottom: none !important
  border-radius: 30px !important
  padding: 12px 23px !important 
  transform: none !important
  @media (max-width: 670px)
    width: 72px !important
    height: 36px !important
    padding: 8px 16px !important 
  @media screen and (min-width: 2200px)
    height: 2.17rem !important
    border-radius: 1.5rem !important
    padding: 0.4rem 1.15rem !important 
  &--text
    font-size: 20px !important
    line-height: 28px !important
    font-weight: 400 !important
    word-spacing: 0 !important
    border-bottom: 0 !important
    padding: 0 !important
    @media (max-width: 670px)
      font-size: 13px !important
      line-height: 20px !important
    @media screen and (min-width: 2200px)
      font-size: 1rem !important
      line-height: 1.4rem !important
  &--block
    position: absolute !important
    bottom: 60px !important
    right: 0 !important
    flex-direction: column-reverse !important
    background: rgba(235, 235, 235, 0.85) !important
    border-radius: 15px 15px 15px 15px !important
    @media screen and (min-width: 2200px)
      bottom: 3rem !important
      border-radius: 0.75rem 0.75rem 0.75rem 0.75rem !important
    &-item
      font-size: 20px !important
      line-height: 28px !important
      font-weight: 400 !important
      padding: 12px 22px !important
      word-spacing: 0 !important
      @media (max-width: 670px)
        font-size: 13px !important
        line-height: 20px !important
        padding: 8px 16px !important
      @media screen and (min-width: 2200px)
        font-size: 1rem !important
        line-height: 1.4rem !important
        padding: 0.6rem 1.1rem !important

.widget-enter-fullscreen
  @media (max-width: 1025px)
    top: 63px !important
    right: 0 !important
  @media (max-width: 670px)
    top: 68px !important
    padding: 10px !important
  @media screen and (min-width: 2200px)
    top: 1.8rem !important
    right: 2.25rem !important
    padding: 0.5rem !important
  img
    @media (max-width: 670px)
      width: 40px !important
      height: 40px !important
    @media screen and (min-width: 2200px)
      width: 2.5rem !important
      height: 2.5rem !important

.widget-exit-fullscreen
  @media (max-width: 1025px)
    top: 63px !important
    right: 0 !important
  @media (max-width: 670px)
    top: 68px !important
    padding: 10px !important
  @media screen and (min-width: 2200px)
    top: 1.8rem !important
    right: 2.25rem !important
    padding: 0.5rem !important
  img
    @media (max-width: 670px)
      width: 40px !important
      height: 40px !important
    @media screen and (min-width: 2200px)
      width: 2.5rem !important
      height: 2.5rem !important

@media screen and (min-width: 2200px)
  html
    font-size: 20px !important

@media screen and (min-width: 2560px)
  html
    font-size: 24px !important

@media screen and (min-width: 2960px)
  html
    font-size: 32px !important

@media screen and (min-width: 5000px)
  html
    font-size: 40px !important

.w-back
  position: fixed
  display: none
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: #fff
  z-index: 10

.widget-tab__panorama-close-map
  top: 70px !important
  right: 12px !important
  padding: 0 !important
  @media (max-width: 351px)
    top: 66px !important
  img
    width: 46px !important
    height: 46px !important